export const dates = [
  {
    startDate: '2020-01-01T00:00:00.000Z',
    endDate: '2020-12-31T23:59:59.999Z',
  },
  {
    startDate: '2020-01-01T00:00:00.000Z',
    endDate: '2020-03-31T23:59:59.999Z',
  },
  {
    startDate: '2020-04-01T00:00:00.000Z',
    endDate: '2020-06-30T23:59:59.999Z',
  },
  {
    startDate: '2020-07-01T00:00:00.000Z',
    endDate: '2020-09-30T23:59:59.999Z',
  },
  {
    startDate: '2020-10-01T00:00:00.000Z',
    endDate: '2020-12-31T23:59:59.999Z',
  },
  {
    startDate: '2020-01-01T00:00:00.000Z',
    endDate: '2020-06-30T23:59:59.999Z',
  },
  {
    startDate: '2020-07-01T00:00:00.000Z',
    endDate: '2020-12-31T23:59:59.999Z',
  },
];

export const quartersWithData: any = {
  '2022-23': true,
  Q1: false,
  Q2: false,
  Q3: false,
  Q4: false,
};

export const outComeDropdownValues = [
  {
    value: 'Bookings New Logos',
    label: 'Bookings (New Logos)',
  },
  {
    value: 'Bookings Paid Pilots',
    label: 'Bookings (Paid Pilots)',
  },
  // {
  //   value: 'Deal Size',
  //   label: 'Deal Size (Amount)'
  // },
  // {
  //   value: 'Sales Cycle',
  //   label: 'Sales Cycle (Days)'
  // },
];

export const roleName: { [key: string]: string } = {
  ADMIN: 'Admin',
  LEADER: 'Manager',
  AE: 'Rep',
  EXEC: 'Exec',
};

export const personaList = [
  {
    value: 'ADMIN',
    name: 'Admin',
  },
  {
    value: 'LEADER',
    name: 'Manager',
  },
  {
    value: 'AE',
    name: 'Rep',
  },
  {
    value: 'EXEC',
    name: 'Exec',
  },
  {
    value: 'SDR',
    name: 'SDR',
  },
];

export const fiveBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Middle</span><span style="display:flex;justify-content:center;">Performer</span></div>',
  '3':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '4':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const threeBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Middle</span><span style="display:flex;justify-content:center;">Performer</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const fourBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '3':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const fiveBubbleHeaders: { [key: number]: string } = {
  '1': 'Low - Performers',
  '2': 'Below Average - Performers',
  '3': 'Mid - Performers',
  '4': 'High - Performers',
  '5': 'Out - Performers',
};
export const fourBubbleHeaders: { [key: number]: string } = {
  '1': 'Low - Performers',
  '2': 'Mid - Performers',
  '3': 'High - Performers',
  '4': 'Out - Performers',
};
export const threeBubbleHeaders: { [key: number]: string } = {
  '1': 'Low - Performers',
  '2': 'Mid - Performers',
  '3': 'High - Performers',
};

export const percentageDrivers = [
  'Total Connects / Attempts',
  'Future Interest / Connects',
  'Followups / Connects',
  'Meetings Rescheduled / Connects',
];

export const colorCode = [
  { primary: 'rgb(224, 69, 50)', secondary: 'rgb(224  69  50 / 20%)' },
  { primary: 'rgb(251, 160, 92)', secondary: 'rgb(251  160  92 / 20%)' },
  { primary: 'rgb(15, 130, 68)', secondary: 'rgb(15  130  68 / 20%)' },
  {
    primary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(241, 111, 68)"
          ],
          [
              1,
              "rgb(191, 228, 123)"
          ]
      ]
  }`,
    secondary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(241  111  68 / 20%)"
          ],
          [
              1,
              "rgb(191  228  123 / 20%)"
          ]
      ]
  }`,
  },
  { primary: 'rgb(0, 104, 55)', secondary: 'rgb(0  104  55 / 20%)' },
  { primary: 'rgb(229, 80, 54)', secondary: 'rgb(229  80  54 / 20%)' },
];
